const openModal = (modalWrapper) => {
  modalWrapper.classList.add("show");
};

const showAndHideClipsSections = (clipsSections, selectedClip) => {
  clipsSections.forEach((element) => {
    if (element.id !== selectedClip) {
      element.style.display = "none"; // eslint-disable-line
    } else {
      element.style.display = "grid"; // eslint-disable-line
    }
  });
};

const showAndHideRemoval = (action, modalRemovalClip) => {
  modalRemovalClip.style.display = action === "show" ? "grid" : "none"; // eslint-disable-line

  const clipsWrapper = modalRemovalClip.querySelector(".clips-single-section");

  clipsWrapper.style.display = "grid";
};

const closeModal = (modalWrapper, clipsSections, modalRemovalClip) => {
  modalWrapper.classList.remove("show");

  showAndHideClipsSections(clipsSections, "");
  showAndHideRemoval("hide", modalRemovalClip);
};

export default function clipsModal() {
  const modalWrapper = document.getElementById("clips-modal-wrapper");
  const closeBtn = document.querySelector(".clips-modal-close");

  const clipsButtons = document.querySelectorAll(
    ".single-clip-wrapper .single-clip-button"
  );

  const clipsSections = document.querySelectorAll(
    ".clips-section-wrapper .clips-single-section"
  );

  const clipsRemovalBtns = document.querySelectorAll(".modal-removal-btn");
  const modalRemovalClip = document.getElementById("clips-removal-wrapper");

  if (!modalWrapper || !closeBtn || !clipsButtons || !clipsSections) {
    return;
  }

  [...clipsButtons].forEach((e) => {
    e.addEventListener("click", () => {
      const selectedBtn = e.querySelector("span");
      const selectedClip = selectedBtn.dataset.clip;

      openModal(modalWrapper);
      showAndHideClipsSections(clipsSections, selectedClip);
    });
  });

  if (clipsRemovalBtns) {
    [...clipsRemovalBtns].forEach((removalBtn) => {
      removalBtn.addEventListener("click", () => {
        openModal(modalWrapper);
        showAndHideRemoval("show", modalRemovalClip);
      });
    });
  }

  closeBtn.addEventListener("click", () => {
    closeModal(modalWrapper, clipsSections, modalRemovalClip);
  });
}

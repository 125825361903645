export default function selects() {
  // Do not Executes on WooCheckout
  if (document.body.classList.contains("woocommerce-checkout")) {
    return;
  }

  //Wrap selects in custom output
  customizeSelectOutput();

  //Search and Filter Pro - Re-wrap its select in .custom-select on ajax requests
  if (jQuery) {
    if (document.querySelector(".searchandfilter")) {
      const dw_sf_form = document.querySelector(".searchandfilter");

      jQuery(document).on("sf:ajaxfinish", ".searchandfilter", function () {
        if (dw_sf_form && !dw_sf_form.querySelector(".custom-select")) {
          customizeSelectOutput(".searchandfilter");
        }
      });
    }
  }

  //WooCommerce Table Pro - Re-wrap its select in .custom-select on ajax requests
  if (document.querySelector(".wcpt")) {
    const dw_wpct = document.querySelector(".wcpt");

    jQuery(document).ajaxComplete(function (event, xhr, settings) {
      // Check if it's the Product Table PRO AJAX request
      if (
        settings.url.indexOf("wc-ajax=wcpt_ajax") !== -1 &&
        xhr.status === 200
      ) {
        customizeSelectOutput(".wcpt");
      }
    });
  }
}

function customizeSelectOutput(parentSelector = false) {
  const parentElement = parentSelector
    ? document.querySelector(parentSelector)
    : false;
  const allSelects = !parentElement
    ? document.querySelectorAll("select")
    : parentElement.querySelectorAll("select");

  if (!allSelects) {
    return;
  }

  allSelects.forEach((element) => {
    const wrapper = document.createElement("div");
    wrapper.classList.add("custom-select");

    element.parentNode.insertBefore(wrapper, element);

    wrapper.appendChild(element);
  });
}

const showModal = (contentToShow, modalWrapper) => {
  // stop scrolliing in the page when modal is open
  document.body.classList.add("overflow-hidden");

  modalWrapper.classList.add("show", `show-${contentToShow}`);
};

const closeModal = (contentToShow, modalWrapper) => {
  // stop scrolliing in the page when modal is open
  document.body.classList.remove("overflow-hidden");

  modalWrapper.classList.remove("show", `show-${contentToShow}`);
};

export default function modal() {
  const showModalBtns = document.querySelectorAll(".show-modal");
  const closeBtn = document.querySelector(".close-modal");
  const modalWrapper = document.querySelector(".modal-wrapper");

  if (!showModalBtns || !closeBtn) {
    return;
  }

  [...showModalBtns].forEach((showModalBtn) => {
    const contentToShow = showModalBtn.dataset.modal;

    showModalBtn.addEventListener("click", () => {
      showModal(contentToShow, modalWrapper);
    });
    closeBtn.addEventListener("click", () => {
      closeModal(contentToShow, modalWrapper);
    });
  });
}

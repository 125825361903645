export default function backToTop() {
  const { height } = document.documentElement.getBoundingClientRect();

  const backButton = document.getElementById("back-to-top");

  window.addEventListener("scroll", () => {
    const { scrollTop } = document.documentElement;

    if (scrollTop >= height * 0.25) {
      backButton.classList.add("show");
    } else {
      backButton.classList.remove("show");
    }
  });
}

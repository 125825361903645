//On change of the variation form selects, update the general price displayed to show the new variation price
export default function productVariationPricing() {
  //Only active when woocommerce is active
  if (!document.body.classList.contains("woocommerce")) return;

  //Make sure product is variable and variation form is accessible
  let variationWrapper = document.querySelector(".single_variation_wrap");

  if (!variationWrapper) return;

  // Get the general product price holder
  let generalProductPriceHolder = document.querySelector(".summary .price");

  if (!generalProductPriceHolder) return;

  //Save the initial pricing html showing the range of prices for all variations
  let initialPriceHtml = generalProductPriceHolder.innerHTML;

  // Create a MutationObserver to detect changes in the variation wrapper
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      // Check if the mutation added or changed the variation price element
      let variationPriceHolder = document.querySelector(
        ".single_variation_wrap span.price"
      );

      if (variationPriceHolder) {
        // Update the general product price with the new variation price
        generalProductPriceHolder.innerHTML = variationPriceHolder.innerHTML;
      }

      //If variation is hidden, you have not selected any variation property and can't move forward
      //Reset the HTML of the general price to be the default one with range of prices of all variations
      const variation = document.querySelector(".single_variation");
      if (!variation) return;

      const variationIsHidden =
        window.getComputedStyle(variation).display === "none";

      if (variationIsHidden) {
        generalProductPriceHolder.innerHTML = initialPriceHtml;
      }
    });
  });

  // Observe changes in the variation wrapper's child nodes
  observer.observe(variationWrapper, {
    attributes: true, //Need to listen to attributes since we watch if variation is displayed
    childList: true, // Listen for additions or removals of child nodes
    subtree: true, // Watch for changes in all descendants
  });
}
